const data = [
    {
        id: 1,
        name: "ACEtoberfest", 
        image: "ace.png", 
        link: "https://fb.me/1GGqQMp70hDEND3",
        ticketLink: "https://fb.me/1GGqQMp70hDEND3",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "comingSoon.jpg",
        org: "ACE",
        contact: "Van Wilcox (409) 239-4251"
    },
    {
        id: 2,
        name: "Phi Delt Las Vegas", 
        image: "phidelt.jpg", 
        link: "https://www.eventbrite.com/e/phi-delt-las-vegas-tickets-731622431387?utm_experiment=test_share_listing&aff=ebdsshios",
        ticketLink: "https://www.eventbrite.com/e/phi-delt-las-vegas-tickets-731622431387?utm_experiment=test_share_listing&aff=ebdsshios",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Day 1 transportation, fanny pack, koozie. Shirt included for girls only",
        tshirt: "phideltShirt.png",
        org: "Phi Delt",
        contact: "@aggiephidelt"
    },
    {
        id: 3,
        name: "Gents & Suits: The Masters", 
        image: "gentsSuits.png", 
        link: "https://my.cheddarup.com/c/the-masters-at-chilifest?cart=18f4b632-c84d-4d55-ac5c-7dbb701b5289%21%2169957679",
        ticketLink: "https://my.cheddarup.com/c/the-masters-at-chilifest?cart=18f4b632-c84d-4d55-ac5c-7dbb701b5289%21%2169957679",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "gentssuitsShirt.png",
        org: "Gents/Suits",
        contact: "Arjun Khale (469) 416-6416"
    },
    {
        id: 4,
        name: "Sigma Chi Yacht Club", 
        image: "sigmachi.png", 
        link: "https://www.cognitoforms.com/AggieSigmaChi1/SIGMACHIYACHTCLUB",
        ticketLink: "https://www.cognitoforms.com/AggieSigmaChi1/SIGMACHIYACHTCLUB",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Included for girls only: Shirt, bandana, sticker. Sold separately: other shirts, hat, fanny pack",
        tshirt: "sigmachiShirt.png",
        org: "Sigma Chi",
        contact: "@tamusigmachi"
    },
    {
        id: 5,
        name: "Pike Saloon", 
        image: "pike.jpg", 
        link: "https://www.facebook.com/events_invite_off_fb/link_sharing/1531239684328617/?inviter_id=100004758856893&token=fxpEXNbxl-iOmcGhiJsTVjBybn6rUaTF4pPU-Caf3Eo",
        ticketLink: "https://www.aggiepike.com/tickets",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Day 1 transportation, cowboy hat, bandana, fanny pack, koozie, live performances",
        tshirt: "pikeShirt.png",
        org: "Pike",
        contact: "Brady Hughes (719) 375-4994"
    },
    {
        id: 6,
        name: "Delts Angels", 
        image: "delt.JPG", 
        link: "https://my.cheddarup.com/c/delts-angels?cart=cf3b4bda-8331-4ce7-9259-341d8b256746%21%2170549347",
        ticketLink: "https://my.cheddarup.com/c/delts-angels?cart=cf3b4bda-8331-4ce7-9259-341d8b256746%21%2170549347",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Fanny pack, bandana, tattoos, drinking straw, and more",
        tshirt: "comingSoon.jpg",
        org: "Delt",
        contact: "@aggiedelts"
    },
    {
        id: 7,
        name: "Kappa Sigma Margaritaville", 
        image: "kappasig.PNG", 
        link: "https://www.facebook.com/groups/347424564563277",
        ticketLink: "https://kappa-sigma-chilifest24.cheddarup.com/",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Live music. T-shirt, hat, fanny pack, koozie, and accessories also available",
        tshirt: "kappaSigShirt.png",
        org: "Kappa Sig",
        contact: "ksigchilifest@gmail.com"
    },
    {
        id: 8,
        name: "Beta Born in the USA", 
        image: "beta.JPG", 
        link: "https://ticketbud.com/events/03ef98f0-ae5d-11ee-b78e-42010a71702b",
        ticketLink: "https://ticketbud.com/events/03ef98f0-ae5d-11ee-b78e-42010a71702b",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "comingSoon.jpg",
        org: "Beta",
        contact: "Joe Ledet (713) 396-9828"
    },
    {
        id: 9,
        name: "Willystock",
        image: "willystock.jpg",
        link: "https://fb.me/1OUr1TTOnjuJ3pD",
        ticketLink: "https://docs.google.com/forms/d/e/1FAIpQLSc6BbQg9fsifNL_19Bj0A-oc9muLjRRSQ9FDctuAhH-s55DEw/viewform?usp=sf_link",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "willyShirt.png",
        org: "     ",
        contact: "@willystock2024"
    },
    {
        id: 10,
        name: "AMA Olympics",
        image: "AMA.jpg",
        link: "https://amaolympics.squarespace.com/",
        ticketLink: "https://amaolympics.squarespace.com/",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Jersey and hat sold separately",
        tshirt: "amaShirt.png",
        org: "AMA",
        contact: "aggiemensalliance@gmail.com"
    },
    {
        id: 11,
        name: "SigEp Shipwrecked",
        image: "sigep.jpg",
        link: "https://www.bouncelife.com/events/6571ef70a2e4796d97f9ecb1",
        ticketLink: "https://www.bouncelife.com/events/6571ef70a2e4796d97f9ecb1",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Captain hat bundle (hat, scarf, and more)",
        tshirt: "sigepShirt.png",
        org: "SigEp",
        contact: "spechili24@gmail.com"
    },
    // {
    //     id: 12,
    //     name: "Phis in Cairo",
    //     image: "comingSoon.jpg",
    //     link: "https://fb.me/e/blSXy29Xp",
    //     ticketLink: "", 
    //     guys: 999,
    //     girls: 999,
    //     guysBuild: 999,
    //     girlsBuild: 999,
    //     included: "",
    //     tshirt: "comingSoon.jpg",
    //     org: "Alpha Sig/Delta Sig/Phi Psi",
    //     contact: "email@gmail.com"
    // },
    {
        id: 13,
        name: "Ol Ags: The Donks",
        image: "ol_ags.JPG",
        link: "https://bagpipe-manatee-myn7.squarespace.com/",
        ticketLink: "https://bagpipe-manatee-myn7.squarespace.com/",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "olAgsShirt.png",
        org: "Ol Ags",
        contact: "olags.president@gmail.com"
    },
    {
        id: 14,
        name: "TKE & ATO Taulapalooza",
        image: "atotke.JPG",
        link: "https://www.taulapalooza.store/",
        ticketLink: "https://www.taulapalooza.store/",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "Day 1 transportation, fanny pack, koozie, live performances, Zach Bryan autographed guitar raffle",
        tshirt: "atotkeShirt.png",
        org: "TKE/ATO",
        contact: "@aggie.ato or @aggietke"
    },
    // {
    //     id: 15,
    //     name: "Space Rodeo",
    //     image: "comingSoon.jpg",
    //     link: "https://fb.me/e/blSXy29Xp",
    //     ticketLink: "",     
    //     guys: 999,
    //     girls: 999,
    //     guysBuild: 999,
    //     girlsBuild: 999,
    //     included: "",
    //     tshirt: "comingSoon.jpg",
    //     org: "SAE",
    //     contact: "email@gmail.com"
    // },
    //     id: 16,
    //     name: "Fiji 500",
    //     image: "comingSoon.jpg",
    //     link: "https://fb.me/e/blSXy29Xp",
    //     ticketLink: "",    
    //     guys: 999,
    //     girls: 999,
    //     guysBuild: 999,
    //     girlsBuild: 999,
    //     included: "",
    //     tshirt: "comingSoon.jpg",
    //     org: "Fiji",
    //     contact: "email@gmail.com"
    // },
    {
        id: 17,
        name: "Paradigm & ABB Grand Prix",
        image: "paradigm.jpg",
        link: "https://my.cheddarup.com/c/paradigmxabb-chilifest?cart=a654b6d7-dbd3-4eec-b222-cea2bf8e3bc0%21%2169934833",
        ticketLink: "https://my.cheddarup.com/c/paradigmxabb-chilifest?cart=a654b6d7-dbd3-4eec-b222-cea2bf8e3bc0%21%2169934833",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "comingSoon.jpg",
        org: "Paradigm/ABB",
        contact: "jakeseal05@gmail.com"
    },
    // },
    // {
    //     id: 18,
    //     name: "One Army",
    //     image: "comingSoon.jpg",
    //     link: "https://fb.me/e/blSXy29Xp",
    //     ticketLink: "",   
    //     guys: 999,
    //     girls: 999,
    //     guysBuild: 999,
    //     girlsBuild: 999,
    //     included: "",
    //     tshirt: "comingSoon.jpg",
    //     org: "One Army",
    //     contact: "email@gmail.com"
    // },
    {
        id: 19,
        name: "AGR Rhodeo Revival",
        image: "agr.png",
        link: "https://my.cheddarup.com/c/alpha-gamma-rho-chilifest-2024-tickets?cart=5b405bce-e3bd-4109-a069-ca5618ada740%21%2170781114&fbclid=PAAaZUiAMsuqG_vkDVZxu7-DTg4Xat6Pqo1VCVS2yRBB_Jxc7C5vMT5BVje9Y_aem_AVnUQXHACmseI49A_r8_foMsL_vL7pAT4XbNJ-dCy6lcXPQv3R91ujNOKPNlD3AyZv8",
        ticketLink: "https://my.cheddarup.com/c/alpha-gamma-rho-chilifest-2024-tickets?cart=5b405bce-e3bd-4109-a069-ca5618ada740%21%2170781114&fbclid=PAAaZUiAMsuqG_vkDVZxu7-DTg4Xat6Pqo1VCVS2yRBB_Jxc7C5vMT5BVje9Y_aem_AVnUQXHACmseI49A_r8_foMsL_vL7pAT4XbNJ-dCy6lcXPQv3R91ujNOKPNlD3AyZv8",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "",
        tshirt: "agrShirt.png",
        org: "AGR",
        contact: "@agr_tamu"
    },
    {
        id: 20,
        name: "Theta Chi Chiami Beach",
        image: "thetachi.png",
        link: "https://www.bouncelife.com/events/65c315e665c73ae1c154022b",
        ticketLink: "https://www.bouncelife.com/events/65c315e665c73ae1c154022b",
        guys: "--",
        girls: "--",
        guysBuild: "--",
        girlsBuild: "--",
        included: "$500 pong tournament entry (build-only tickets excluded)",
        tshirt: "thetachiShirt.png",
        org: "Theta Chi",
        contact: "@aggiethetachi"
    }
];

export default data;